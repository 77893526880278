import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout.component';
import { ProtectedGuard } from 'ngx-auth';
import { HomeComponent } from '../../pages/home/home.component';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        loadChildren: () => import('../../pages/home/home.module').then(m => m.HomeModule),
        canActivate: [ ProtectedGuard ],
      },
      {
        path: 'users',
        loadChildren: () => import('../../pages/users/users.module').then(m => m.UsersModule),
        canActivate: [ ProtectedGuard ],
      },
      {
        path: 'delivery-notes',
        loadChildren: () => import('../../pages/delivery-notes/delivery-notes.module').then(m => m.DeliveryNoteModule),
        canActivate: [ ProtectedGuard ],
      },
      {
        path: 'documentation',
        loadChildren: () => import('../../pages/documentation/documentation.module').then(m => m.DocumentationModule),
        canActivate: [ ProtectedGuard ],
      },
      {
        path: 'oil',
        loadChildren: () => import('../../pages/oil/oil.module').then(m => m.OilModule),
        canActivate: [ ProtectedGuard ],
      },
      {
        path: 'tolls',
        loadChildren: () => import('../../pages/tolls/tolls.module').then(m => m.TollModule),
        canActivate: [ ProtectedGuard ],
      },
      {
        path: 'incidents',
        loadChildren: () => import('../../pages/incidents/incidents.module').then(m => m.IncidentsModule),
        canActivate: [ ProtectedGuard ],
      },
      {
        path: 'bills',
        loadChildren: () => import('../../pages/bills/bills.module').then(m => m.BillsModule),
        canActivate: [ ProtectedGuard ],
      },
      {
        path: 'map',
        loadChildren: () => import('../../pages/map/map.module').then(m => m.MapModule),
        canActivate: [ ProtectedGuard ],
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule {}
