import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { Notification } from './Notification';

const notifications: Notification[] = [
  {
    id: 1,
    icon: 'notifications',
    name: 'This is a notification',
    time: 'few sec ago',
    read: false,
    colorClass: ''
  },
  {
    id: 2,
    icon: 'shopping_basket',
    name: 'User bought your template',
    time: '23 min ago',
    read: false,
    colorClass: 'primary'
  },
  {
    id: 3,
    icon: 'eject',
    name: 'Server Crashed',
    time: 'an hour ago',
    read: false,
    colorClass: 'accent'
  },
  {
    id: 4,
    icon: 'cached',
    name: 'New user registered',
    time: '6 hours ago',
    read: true,
    colorClass: ''
  },
  {
    id: 5,
    icon: 'code',
    name: 'John added you as friend',
    time: 'yesterday',
    read: true,
    colorClass: ''
  }
];

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  notifications$: Subject<Notification[]> = new BehaviorSubject([]);

  constructor() {
    this.notifications$.next(notifications);
  }

  getNotifications() {
    return this.notifications$;
  }

  dismiss(notification: Notification): void {
    notifications.splice(notifications.indexOf(notification), 1);
    this.notifications$.next(notifications);
  }

  markAsRead(notification: Notification): void {
    notifications.find((noti) => noti.id === notification.id).read = true;
    this.notifications$.next(notifications);
  }

  private _getNotifications() {
    // http
  }

}
