import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../auth';
import { Router } from '@angular/router';
import { UserService } from '../../../auth/user.service';

@Component({
  selector: 'app-toolbar-user-button',
  templateUrl: './toolbar-user-button.component.html',
  styleUrls: ['./toolbar-user-button.component.scss']
})
export class ToolbarUserButtonComponent implements OnInit {
  isOpen: boolean;
  username: string;

  constructor(
    private userService: UserService,
    private router: Router,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.userService
      .getUser()
      .subscribe(user => (this.username = user.username));
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.isOpen = false;
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
