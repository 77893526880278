import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  OnDestroy
} from '@angular/core';
import { LIST_FADE_ANIMATION } from '../../../../shared/list.animation';
import { NotificationService, Notification } from '../../../notifications';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-toolbar-notifications',
  templateUrl: './toolbar-notifications.component.html',
  styleUrls: ['./toolbar-notifications.component.scss'],
  animations: [...LIST_FADE_ANIMATION],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarNotificationsComponent implements OnInit, OnDestroy {
  isOpen: boolean;
  notifications: Notification[];
  notReadNoti: number;
  notSubcription: Subscription;

  constructor(private notificationService: NotificationService) {}

  ngOnInit() {
    this.notSubcription = this.notificationService
      .getNotifications()
      .subscribe(notifications => {
        this.notifications = notifications;
        this.notReadNoti = notifications.filter(noty => !noty.read).length;
      });
  }

  ngOnDestroy(): void {
    this.notSubcription.unsubscribe();
  }

  markAsRead(notification: Notification) {
    this.notificationService.markAsRead(notification);
  }

  dismiss(notification: Notification, event) {
    event.stopPropagation();
    this.notificationService.dismiss(notification);
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.isOpen = false;
  }

  markAllAsRead() {
    this.notifications.forEach(notification => (notification.read = true));
  }
}
