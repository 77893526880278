import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

@NgModule({
  declarations: [AppComponent],
  imports: [
    // Angular Core Module // Don't remove!
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    // Core Modules
    CoreModule,
    AppRoutingModule,
    NgxMaterialTimepickerModule.setLocale('es-ES')

    // Register a Service Worker (optional)
    // ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  // providers: AuthHttpInterceptorProviders,
  bootstrap: [AppComponent]
})
export class AppModule {}
