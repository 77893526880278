import { Injectable } from '@angular/core';
import { User, ApiService } from '..';
import { Subject, ReplaySubject, Observable } from 'rxjs';

import { tap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _user: User;
  public user$: Subject<User> = new ReplaySubject<User>();
  private path = 'user';

  constructor(
    private api: ApiService,
    private authService: AuthenticationService
  ) {
    this.authService.authorized$.subscribe({
      next: (isAuth: boolean) => {
        if (isAuth) {
          this.getUserProfile().subscribe();
        }
      }
    });
  }

  getUserProfile(): Observable<User> {
    return this.api.get<User>('user').pipe(tap(user => this.setUser(user)));
  }

  setUser(user: User) {
    this._user = user;
    this.user$.next(this._user);
  }

  public getUser(): Observable<User> {
    return this.user$.asObservable();
  }

  get user() {
    return this._user;
  }

  public updateUser(newUser: User | any): Observable<User> {
    return this.api
      .put(this.path, newUser)
      .pipe(tap((user: User) => this.setUser(user)));
  }
}
