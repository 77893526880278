import { Pipe, PipeTransform } from '@angular/core';
import { SidenavItem } from '../../core/layout/sidenav/sidenav-item/sidenav-item.interface';

@Pipe({
  name: 'noHome'
})
export class NoHomePipe implements PipeTransform {
  transform(items: SidenavItem[], ...args: any[]): any {
    if (!items) {
      return items;
    }
    return items.filter(item => item.name !== 'Home');
  }
}
