import { Component } from '@angular/core';
import { SidenavItem } from './core/layout/sidenav/sidenav-item/sidenav-item.interface';
import { SidenavService } from './core/layout/sidenav/sidenav.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(sidenavService: SidenavService) {
    const menu: SidenavItem[] = [];

    menu.push({
      name: 'Home',
      routeOrFunction: '/home',
      icon: 'home',
      position: 5
    });

    menu.push({
      name: 'Usuarios',
      routeOrFunction: '/users',
      icon: 'people',
      position: 10
    });

    menu.push({
      name: 'Albaranes',
      routeOrFunction: '/delivery-notes',
      icon: 'chat',
      position: 15
    });

    menu.push({
      name: 'Documentos',
      icon: 'description',
      position: 20,
      open: true,
      subItems: [
        {
          name: 'Documentación',
          routeOrFunction: '/documentation',
          position: 10
        },
        {
          name: 'Gasoil',
          routeOrFunction: '/oil',
          position: 15
        },
        {
          name: 'Peajes',
          routeOrFunction: '/tolls',
          position: 20
        },
        {
          name: 'Incidencias',
          routeOrFunction: '/incidents',
          position: 25
        }
      ]
    });

    menu.push({
      name: 'Facturas',
      routeOrFunction: '/bills',
      icon: 'assignment',
      position: 25
    });
/*
    menu.push({
      name: 'Mapa',
      routeOrFunction: '/map',
      icon: 'map',
      position: 30
    });
*/
    sidenavService.addItems(menu);
  }
}
