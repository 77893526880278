import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SidenavItem } from '../../core/layout/sidenav/sidenav-item/sidenav-item.interface';
import { SidenavService } from '../../core/layout/sidenav/sidenav.service';
import { map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  items$: Observable<SidenavItem[]>;

  constructor(private sidenavService: SidenavService, private router: Router) {}

  ngOnInit() {
    this.items$ = this.sidenavService.items$.pipe(
      map((items: SidenavItem[]) =>
        this.sidenavService.sortRecursive(items, 'position')
      )
    );
  }

  handleClick(item: SidenavItem) {
    if (item.subItems && item.subItems.length > 0) {
      this.sidenavService.toggleItemOpen(item);
    } else if (
      typeof item.routeOrFunction === 'string' ||
      item.routeOrFunction instanceof String
    ) {
      this.router.navigate([item.routeOrFunction]);
    } else if (
      typeof item.routeOrFunction === 'function' ||
      item.routeOrFunction instanceof Function
    ) {
      item.routeOrFunction();
    } else {
      throw Error(
        'Could not determine what to do, Sidenav-Item has no routeOrFunction set AND does not contain any subItems'
      );
    }
  }
}
